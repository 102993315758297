<template>
	<div class="wrapper page-playback-detail">

		<div class="playback-detail">
			<div :class="['info', 'info_' + info.type]">
				<div class="status">——已结束</div>
				<div class="teamname homename">{{ info.home_team_name }}</div>
				<img class="teamlogo" :src="info.home_avatar" alt="" />
				<div class="score">{{ info.home_score }}</div>
				<div class="mtitle">
					{{ info.match_title }}
					<span class="mdatetime">{{ info.match_time }}</span>
				</div>
				<div class="score">{{ info.away_score }}</div>
				<img class="teamlogo" :src="info.away_avatar" alt="" />
				<div class="teamname">{{ info.away_team_name }}</div>
			</div>
			<div class="lists">
				<a :href="item.video" target="_blank" class="item" v-for="item in lists" :key="item.id">{{ item.title
					}}</a>
			</div>
			<div class="video">
				<div class="video-title">热门视频</div>
				<div class="lists">
					<a :href="item.video" target="_blank" class="item" v-for="item in recommends" :key="item.id">{{
				item.title }}</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'detail',
	data() {
		return {
			info: {},
			lists: [],
			recommends: []
		};
	},
	mounted() {
		const { tab, id } = this.$route.params;
		this.$api.get(`/videos/${tab}/${id}`).then(res => {
			if (res.code == 200) {
				const { detail, record, more } = res.data;
				this.info = detail || {};
				this.lists = record || [];
				this.recommends = more || [];
			}
		});
	}
};
</script>

<style lang="less">
@import url('~@/assets/less/variables.less');

.page-playback-detail {
	background: #f7f7f8;

	.banner {
		margin-top: 26px;
		width: 1200px;
		margin-left: auto;
		margin-right: auto;
		height: 90px;
		overflow: hidden;

		img {
			display: block;
			width: 100%;
		}
	}
}

.playback-detail {
	width: 1200px;
	margin: 26px auto 0;
	padding-bottom: 40px;

	.info {
		position: relative;
		height: 104px;
		background: url('../assets/img/playback1.png') no-repeat center center;
		background-size: cover;
		color: #ffffff;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		padding-top: 12px;

		&.info_2 {
			background-image: url('../assets/img/playback2.png');
		}

		.status {
			position: absolute;
			top: 20px;
			left: 0;
			padding: 0 16px 0 12px;
			line-height: 27px;
			background: @primary-color;
			border-radius: 0px 13px 13px 0px;
			color: #ffffff;
		}

		.teamlogo {
			width: 44px;
			height: 44px;
		}

		.score {
			text-align: center;
			width: 68px;
			font-size: 18px;
			font-weight: 500;
		}

		.teamname {
			font-size: 16px;
			flex: 1;
			text-align: left;
			padding: 0 0 0 92px;
		}

		.homename {
			text-align: right;
			padding: 0 92px 0 0;
		}

		.mtitle {
			transform: translateY(10px);
			width: 158px;
			display: flex;
			flex-direction: column;
			align-items: center;
			font-size: 12px;

			span {
				font-size: 16px;
				color: #fffefe;
			}
		}
	}

	.lists {
		.item {
			padding-left: 46px;
			height: 50px;
			border-bottom: #f1f5fa 1px solid;
			display: flex;
			flex-direction: row;
			align-items: center;
			font-size: 14px;
			background: #ffffff url('../assets/img/icon-playback.png') no-repeat 16px center;
			background-size: auto 14px;
			transition: all 0.2s;
			color: #333333;

			a {
				color: #333333;
			}

			// &:hover {
			// 	color: #66a6ff;
			// }
		}
	}

	.video {
		background-color: #ffffff;

		&-title {
			height: 50px;
			padding-top: 18px;
			background: url('../assets/img/icon-video.png') no-repeat 10px 16px;
			background-size: 26px 26px;
			padding-left: 44px;
			font-size: 16px;
			font-weight: 500;
			color: #001e47;
		}

		.lists {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			padding-bottom: 10px;

			.item {
				width: 50%;
				height: 22px;
				font-size: 12px;
				border-bottom: 0;
				background-size: auto 12px;
			}
		}
	}
}
</style>
